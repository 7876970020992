.wrapper {
  padding: 30px;
  background: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  margin-block-end: 100px;
}

.image {
  block-size: 100px;
  display: block;
  margin: 10px auto;

}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  background: rgba(225, 227, 237, 0.2);
  border-radius: 8px;
  padding: 15px;
  inline-size: calc(33.33% - 13.66px);
  border: 1px solid #D3DCEC;
  cursor: pointer;

  @media (min-width: 1200px) {
    inline-size: calc(20% - 16px);
  }

  .past-appointment {
    color: #E96C5D;
  }

  .future-appointment {
    color: #80C8C1;
  }

  &.selected {
    border-color: #80C8C1;
    border-width: 2px;
    background-color: rgba(128, 200, 193, 0.1);
  }
}

.header {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-block-end: 15px;
  padding-block-end: 15px;
  border-block-end: 1px solid #E1E3ED;
}

.name-date-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.name {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-block: 0;
  text-transform: capitalize;
}

.date.date {
  margin-block: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.since-last-eyetest {
  margin-block: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  > span {
    font-weight: 400;
    font-size: 12px;
  }
}

.source.source {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-block: 0;
  color: grey;

  strong {
    font-weight: 500;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.lens {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-block: 0;
}

.staff.staff {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-block: 0;
}

.selected-item {
  inline-size: 100%;
  margin-block-start: 10px;

  @media (min-width: 1200px) {
    display: flex;
    gap: 40px;
  }
}

.cols {
  display: flex;
  gap: 20px;
  margin-block-end: 30px;
  flex-grow: 1;
}

.col {
  inline-size: 50%;
  background: rgba(225, 227, 237, 0.2);
  border-radius: 10px;
}

.eye-title {
  margin-block: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-block: 15px 10px;
  margin-block: 0;
  margin-inline: 15px;
  border-block-end: 1px solid #E1E3ED;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.field {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  block-size: 101px;
  inline-size: 33.33%;
  margin: 0;
  padding: 0;

  &:not(:nth-child(1)),
  &:not(:nth-child(2)),
  &:not(:nth-child(3)) {
    border-block-start: 1px solid rgba(178, 188, 207, 0.25);
  }

  &:nth-child(3n - 1) {
    border-inline-start: 1px solid rgba(178, 188, 207, 0.25);
    border-inline-end: 1px solid rgba(178, 188, 207, 0.25);
  }
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.value {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  .disabled & {
    color: #B2BCCF;
    opacity: 0.5;
  }
}

.comment {
  margin-block: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-inline-size: 500px;
}

.comment-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.comment-body {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  max-width: 300px;
  word-wrap: break-word;
}

.icon {
  inline-size: 32px;
  block-size: 32px;
  object-fit: cover;
  object-position: center;
}

.visual-acuity {
  font-size: 12px;
  margin-block-start: 20px;
  color: #B2BCCF;
}

.appointmentLink {
  color: #80C8C1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.commentAndPrintWrapper {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.printButton {
  border-radius: 12px;
  border: 1px solid rgba(49, 57, 95, 0.7);
  color: #fff;
  padding: 4px 12px;
  text-align: center;
  font-weight: 500;
  background-color: #61B486;
  align-self: flex-end;
  font-size: 12px;
  line-height: 16px;
  &:hover {
    opacity: 0.8;
  }

  .printIcon {
    width: 12px;
    margin: 8px 8px 0px 0px;
  }
}

.divider {
  border-block-end: 1px solid #E1E3ED;
  margin-block: 20px;
}

.title {
  margin-block: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-top: 16px;
}

.header-right {
  display: flex;
  gap: 16px;
  align-items: center;
}

.dialogClassname {
  min-width: 600px;
  min-height: 400px;
}
.siview-success {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.siview-success-cta {
  display: flex;
  gap: 16px;
  justify-content: space-evenly;
  align-items: center;

}

.name-upload {
 min-width: 300px;
 margin-bottom: 16px;
}