.metricsWrapper {
  background-color: #fff;
  padding: 20px;
  display: flex;
  // justify-content: space-between;
  gap: 2Vw;
}

.tabularSummaryWrapper {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 16px;
  margin: 16px;
}

.metricHeading {
  padding-left: 12px;
}

.filter {
  // background-color: #fff;
  // box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  padding: 12px;
  // position: fixed;
  // inset-block-start: 70px;
  // inset-inline: 0;
  // inset-block-start: 70px;
  // border-block-start: 1px solid #E1E3ED;
  // margin-bottom: 16px;
  z-index: 1;
  display: flex;
  gap: 16px;
}

.dateRangeWrapper {
  margin-top: 12px;
  position: fixed;
  z-index: 1;
}

.dateDisplay {
  inline-size: auto;
  min-inline-size: 200px;
}

.link {
  color: #80C8C1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}